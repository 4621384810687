/* src/App.css */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.loader-container {
  position: fixed; /* Ensures the loader stays on top */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Faded background with 50% opacity */
  z-index: 9999; /* Ensures the loader is above all other elements */
}

.loader-logo {
  width: 200px;
  height: auto;
  animation: pulse 2s ease-in-out infinite; /* Optional: Pulsing effect */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.logout-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #ff0000; /* You can change the color */
}

input::-webkit-date-and-time-value {
  text-align: left;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

select {
  padding-right: 10px;
}

select::-ms-expand {
  display: none;
}

select.form-select{
  --bs-form-select-bg-img:none;
  padding: 1rem .75rem;
}

.form-select.is-invalid:not([multiple]):not([size]), 
.form-select.is-invalid:not([multiple])[size="1"], 
.was-validated .form-select:invalid:not([multiple]):not([size]), 
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]), 
.form-select.is-valid:not([multiple])[size="1"], 
.was-validated .form-select:valid:not([multiple]):not([size]), 
.was-validated .form-select:valid:not([multiple])[size="1"] {
    background-position: right calc(.375em + .1875rem) center!important;
}
