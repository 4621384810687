body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-group-item.selected{
  background-color: rgba(var(--bs-primary-rgb),0.125);
}


@media (min-width: 600px) {
  .customer-form {
    display: flex;
    flex-wrap: wrap;
  }
  
  .form-item {
    display: inline-block;
    width: 49%;
    margin-right: 1%;  }

  .form-item:nth-child(2n) {
    margin-right: 0; /* Remove margin on every second item in the row */
  }
}